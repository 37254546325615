import React from 'react'
import {  Container, Typography, Grid  } from '@material-ui/core';
import styles from './styles/AboutStyles'
import aboutImg from './../assets/PRO_STRAND_ABOUT_V1.jpg'

export default function About() {
    const classes = styles();
    return (
<div
        id="about"
        className={classes.aboutDiv}
      >
        <Container
          maxWidth="lg"
          style={{
            flexGrow: "1",
          }}
        >
          <Typography align="center" className={classes.pagetitle}>
            About Us
          </Typography>
  
          <div style={{ flexBasis: "none" }}>
            <Grid container>
              <Grid
                item
                lg={5}
                xs={12}
                md={4}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <img src={aboutImg} className={classes.aboutimg} alt="aboutimg" /> 
              </Grid>
  
              <Grid item lg={7} xs={12} md={8} style={{ padding: "0%" }}>
                <Typography className={classes.pagecontent} component="p">
                We created the PRO Strand brand because we believe our customers deserve consistent, high-quality stranded steel cable and wire rope. Since almost all steel cable sold in the US is imported, it can be difficult to find the right quality product, right when you need it.
                </Typography>
                <Typography className={classes.pagecontent} component="p">
                So, we went right to the source. We personally visit our manufacturing partners to inspect every stage of the production process and document our stringent quality requirements. Our galvanized cable and wire rope products are always hot-dip galvanized for maximum service life, not electro-galvanized like some other brands.
                </Typography>
                <Typography className={classes.pagecontent} component="p">
                We don’t stop there, either. We inspect every foot of cable we sell to verify quality and consistency before it gets to you, so you know that you’re receiving a reliable PRO Strand product every time you order. 
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    )
}
