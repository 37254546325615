import React from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "./styles/WhereToBuyStyles";
import erigging from './../assets/erigging.png'


export default function Wheretobuy() {
  const classes = styles();
  return (
    <Container
    maxWidth="md"
    style={{
      marginBottom:'15px',
      padding:'15px',
      display: "flex",
      alignItems: "center", 
      justifyContent:'center', 
      border:'10px solid #e4e4e4'
    }}
  >
    <div style={{ flexBasis: "none", marginBottom: '30px' }}>   
            <p  style={{fontSize:'30px'}} align="center"  >
            Where to Buy
            </p>   
        <Grid container direction="row" spacing={6} justify="center" alignItems="center" >           
            <Grid item md={12} xs={12} style={{flexBasis:'0'}} >
            <a href="https://www.e-rigging.com/Pro-Strand-Products" target="_blank" rel="noopener noreferrer">
                <img src ={erigging} className={classes.imgProduct} alt=""/></a>
            </Grid>
            {/* <Grid item md={4} xs={12} style={{flexBasis:'0'}}>
            <a href="https://www.amazon.com/s?k=Thor-Tex" target="_blank" rel="noopener noreferrer">
                <img src ={wtb1} className={classes.img} alt=""/></a>
            </Grid>    */}
            {/* <Grid item md={6} xs={12} style={{flexBasis:'0'}}>
            <a href="https://sldrigging.com/contactform" target="_blank" rel="noopener noreferrer">
                <img src ={sld} className={classes.img} alt=""/></a>
            </Grid> */}
        </Grid>    
        </div>   
        </Container>
    
  );
}














