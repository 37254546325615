import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const GalvanizedWireRope = () => {
  const classes = styles();
  return (
    <div>
        <Helmet>
        <title>
          Galvanized Wire Rope
        </title>
      </Helmet>
      <Typography
        align="center"
        className={classes.pagetitle}
      >
        Galvanized Wire Rope
      </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
                small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/3-8-5000-pro-strand-7x19-hot-dip-galvanized-cable-reel.jpg?v=1719947620"
                large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/3-8-5000-pro-strand-7x19-hot-dip-galvanized-cable-reel.jpg?v=1719947620"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt=""
            />
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/galvanized-7x19-cable-material-type-graphic.jpg?v=1719947620"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/galvanized-7x19-cable-material-type-graphic.jpg?v=1719947620"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
            />
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Galvanized Wire Rope"
          subtitle="PRO Strand Galvanized Wire Rope is always hot-dip galvanized for maximum corrosion protection and service life."
          p1="  Our galvanized wire rope is a great general-purpose stranded steel cable perfect for winches, lifting equipment, and creating hoist slings.
          "
          p2="Our 1/2” galvanized wire rope weighs 0.45 lbs per foot and adheres to federal specification RR-W-410H. The working load limit is 2.66 tons, and the minimum breaking strength is 13.3 tons. Our 1/2” wire rope is available in 250’, 500’, and 1000’ spools.
          "
          img="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/half-inch-x-250-foot-iwrc-galvanized-wire-rope-specification-diagram.jpg?v=1724771992"
        />
       

        {/* <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Galvanized Wire Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#FFF100" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Cable Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter with Coating
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Breaking Strength
                </TableCell>
                <TableCell className={classes.theader}>
                  Working Load Limit 5:1 Design Factor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403005</TableCell>
              <TableCell className={classes.tcell}>1/4"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>2000 lbs	</TableCell>
              <TableCell className={classes.tcell}>15.30 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403007</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>300 ft	</TableCell>
              <TableCell className={classes.tcell}>4400 lbs	</TableCell>
              <TableCell className={classes.tcell}>11.10 lbs</TableCell>
              </TableRow>
             
            </TableBody>
          </Table>
        </TableContainer> */}
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default GalvanizedWireRope;
