import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const GalvanizedCable = () => {
  const classes = styles();
  return (
    <div>
        <Helmet>
            <title>
            Galvanized Cable
            </title>
        </Helmet>
      <Typography
        align="center"
        className={classes.pagetitle}
      >
        Galvanized Cable
      </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
                small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-sixteenths-Galvanized-Cable-alt.jpg?v=1651863922"
                large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-sixteenths-Galvanized-Cable-alt.jpg?v=1651863922"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt=""
            />
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/galvanized-7x19-cable-material-type-graphic.jpg?v=1719947620"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/galvanized-7x19-cable-material-type-graphic.jpg?v=1719947620"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
            />
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Galvanized Cable"
          subtitle="PRO Strand Galvanized Cable is always hot-dip galvanized for maximum corrosion protection and service life."
          p1=" Our galvanized wire cable is a great general-purpose stranded steel cable. 
          "
          p2="Our galvanized cable comes in flexible 7x19 construction and the more rigid 7x7 strand configuration. Both constructions comply with federal specification RR-W-410H."
          p3="The 7x19 cable is available in diameters from 3/32” to 3/8” and lengths from 250’ to 5000’.
          "
          p4="The 7x7 cable is available in 1/16” diameter and in 500’ and 1000’ lengths.
          "
          img="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/three-thirty-seconds-inch-x-250-foot-hot-dip-galvanized-cable-specification-diagram.jpg?v=1719947620"
        />
       

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          7x19 Galvanized Cable
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#FFF100" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Cable Diameter
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Breaking Strength
                </TableCell>
                <TableCell className={classes.theader}>
                  Working Load Limit 5:1 Design Factor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell className={classes.tcell}>21004025	</TableCell>
              <TableCell className={classes.tcell}>3/32"	</TableCell>
              <TableCell className={classes.tcell}>1,000 lbs</TableCell>
              <TableCell className={classes.tcell}>200 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21005025	</TableCell>
              <TableCell className={classes.tcell}>1/8"	</TableCell>
              <TableCell className={classes.tcell}>2,000 lbs	</TableCell>
              <TableCell className={classes.tcell}>400 lbs</TableCell>
             
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21015025	</TableCell>
                <TableCell className={classes.tcell}>3/16"	</TableCell>
                <TableCell className={classes.tcell}>4,200 lbs	</TableCell>
                <TableCell className={classes.tcell}>840 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21020010	</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}>7,000 lbs	</TableCell>
                <TableCell className={classes.tcell}>1,400 lbs</TableCell>
              </TableRow>

              <TableRow>
              <TableCell className={classes.tcell}>21025010	</TableCell>
                <TableCell className={classes.tcell}>5/16"	</TableCell>
                <TableCell className={classes.tcell}>9,800 lbs	</TableCell>
                <TableCell className={classes.tcell}>1,960 lbs</TableCell>
              </TableRow>
              
              <TableRow>
              <TableCell className={classes.tcell}>21030010	</TableCell>
                <TableCell className={classes.tcell}>3/8"	</TableCell>
                <TableCell className={classes.tcell}>14,400 lbs	</TableCell>
                <TableCell className={classes.tcell}>2,880 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          7x7 Galvanized Cable
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#FFF100" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Cable Diameter
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Breaking Strength
                </TableCell>
                <TableCell className={classes.theader}>
                  Working Load Limit 5:1 Design Factor
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>21002050	</TableCell>
                <TableCell className={classes.tcell}>1/16"	</TableCell>
                <TableCell className={classes.tcell}>480 lbs</TableCell>
                <TableCell className={classes.tcell}>96 lbs</TableCell>
              </TableRow>

      
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default GalvanizedCable;
