
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {  Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import MChimp from './Mailchimp'
import {animateScroll as scroll} from 'react-scroll';

function Copyright() {
  return (
    <Typography variant="body2" style={{color: 'black', fontFamily: 'Lato'}}>      
       <Link to='/privacy-policy' style={{textDecoration: 'none', color: 'black'}}>Privacy Policy |</Link>
       <Link to='/terms-of-use' style={{textDecoration: 'none', color: 'black'}}> Terms of Use | </Link>
       <Box display={{ xs: 'block', sm: 'none' }}><br/></Box>
      {'Copyright ©     '}
      <Link to='/' color="inherit" 
      style={{textDecoration: 'none', color: 'black'}}>
         Sea-Land Distributors, LLC. 
      </Link>
      {new Date().getFullYear()}
  
    </Typography>
  );
}


const useStyles = makeStyles(theme => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    footer: {   
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    headerlink:{
      textDecoration: 'none',
      color: 'black',
      fontFamily: 'Lato',
      fontSize: '25px',
  },
    link:
    {
      textDecoration: 'none',
      color: 'black',
      fontSize: '16px',
      fontFamily: 'Lato'
    },
    textfield:{
      margin: '5px',
      backgroundColor: 'white',
      color: 'black',
      borderRadius: '20px',
      paddingLeft: '8px'
  },
    signup:{
      color: 'white', 
      border: '2px solid white', 
      padding: '4px',
      margin: '3px',
    }, 
  }));

  const handleClick = (event) => {
    scroll.scrollToTop();
  };

export default function Footer() {
  const classes = useStyles(); 
  return (
    <div style={{backgroundColor: '#fff100', borderTop: '1px solid #e4e4e4'}}>
    <Container maxWidth="lg" component="footer" className={classes.footer} >
        <Grid container spacing={2}>        
            <Grid item xs={6} sm={3} md={4} lg={4} >
              <Typography gutterBottom className={classes.headerlink}>
               Pro-Strand</Typography>
              <ul>    
              <Link to='/' className={classes.link} onClick={handleClick}><li>Home  </li></Link>  
              <a href='/#contact' className={classes.link}><li>Contact  </li> </a>  
              <a href='/#about' className={classes.link}><li>About </li> </a>
              <a href='/#where-to-buy' className={classes.link}><li>Where to Buy </li>  </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.e-rigging.com/Pro-Strand-Products" className={classes.link}><li>Products </li>  </a>               
              </ul>
            </Grid>  
            <Grid item xs={6} sm={3} md={4} lg={4}>
              <Typography gutterBottom className={classes.headerlink}>                
              Quick Links
              </Typography>                 
              <ul>
              <Link to='/galvanized-wire-rope' className={classes.link}><li>Galvanized Wire Rope</li></Link>  

              <Link to='/galvanized-cable' className={classes.link}><li>Galvanized Cable  </li></Link>    
              <Link to='/vinyl-coated-cable' className={classes.link}><li>Vinyl Coated Cable </li></Link>
              </ul>              
            </Grid>  
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography className={classes.headerlink} style={{paddingLeft:'5px', paddingBottom: '5px'}}> N E W S L E T T E R</Typography>             
            <Typography className={classes.link} style={{paddingLeft:'5px'}}>Sign up for new Pro-Strand releases</Typography>                       
            <MChimp />
        </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <div style={{marginBottom: '30px'}}></div>
      </Container>
      </div>
  );
}