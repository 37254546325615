import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  main: {
    paddingBottom: "20px",
    minHeight: "50vh"
  },
  pagetitle:{
      fontFamily:'Lato',
      paddingTop:'30px',
      fontSize: '42px',
      paddingBottom: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: '30px',
     }
    },
    pagesubtitle:{
     fontFamily: 'Lato',
     fontWeight: "bold",
     margin: '15px',
     fontSize: '22px',
     [theme.breakpoints.down("sm")]: {
      fontSize: '19px',
   }
 },
 pagecontent: {
     fontFamily: 'Lato',
     margin: '10px',
     fontSize: '18px'
 },
 img: {
     height: '250px', 
     padding:'10px',
     marginBottom:'20px',
   
 },
 link: {   
     fontFamily: 'Lato',
     margin: '25px',
     fontSize: '32px',
     float: 'right',
     '&:hover': {
         transform:'scale(1.1)',
         transition: 'transform .3s ease',
        }
 },
 card: {

     padding: '5px',
     
 },
 theader: {
     fontWeight: 'bold',
     fontFamily: 'Lato',
     fontSize: '18px',
     color: 'black'
    },
    tcell:{
      fontFamily: 'Lato' 
    },
  shopButtonDiv: {
    backgroundColor: '#FFF10'
  },
  shopButtonLink: {
   color: 'white'
  },
  shopButton: {
    margin: "0 auto", fontFamily: "Lato"
  },
  descriptionDiv: {
    backgroundColor: "#FFF100",
    paddingLeft: "20px",
    marginBottom: 0,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  }

  }));