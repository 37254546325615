import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header1 from '../assets/PRO_STRAND_BANNER_PHASE1_V3.jpg'
import Header2 from '../assets/Pro_Strand_banner2.png'
import Header3 from '../assets/PRO_STRAND_BANNER_PHASE1_V1.jpg'
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';
import BackToTop from './Backtotop';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Helmet } from 'react-helmet'
const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: "5%",
  width: "3%",
  height: "100%",
  color: "#FFF100",
  cursor: "pointer",
  "&hover": {
    top: 0,
    bottom: 0,
    backgroundColor: "lightgrey",
    opacity: ".4",
  },
};


function Home () { 
        return (
          <div>
            <Helmet>
              <title>
                Pro-Strand
              </title>
            </Helmet>
            <BackToTop/>                                               
            <Carousel 
              autoPlay 
              transitionTime={1000} 
              interval={8000}
              infiniteLoop 
              showThumbs={false}  
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <ArrowBackIosIcon
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, left: 15 }}
                  />
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <ArrowForwardIosIcon
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, right: 15 }}
                  />
                )
              }
            >
                <div><img src={Header1} alt="" /></div>
                <div><img src={Header3} alt=""/></div>
                <div><img src={Header2} alt=""/></div> 
            </Carousel>   
            <Wheretobuy/>              
            <Contact/> 
            <About/>  
        </div>
        );
      }
      
 export default Home;

 