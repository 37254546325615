import React  from 'react';
import Mailchimp from 'react-mailchimp-form'

const MChimp = () => {
    return (
        <div
        style={{          
          padding: 5,
        }}
      >
        <Mailchimp
        action='https://E-Rigging.us18.list-manage.com/subscribe/post?u=79ae2d8078facdd05bd62257b&amp;id=702ad4f2f3' method="post"
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
         
        ]}
        messages = {
          {
            sending: "Subscribing...",
            success: "Subscription successful!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "Too many subscribe attempts for this email address",
            button: "Sign up"
          }
        }
        className='form'
        />
        </div>
    );
  }

 
export default MChimp;