import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallIcon from '@material-ui/icons/Call';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import MessageIcon from '@material-ui/icons/Message';

const useStyles = makeStyles({  
  root: {
    flexGrow: 1,
  },
  map: {
    height: 'auto',
    maxWidth: '70px',
  },
  content: {
      padding: '20px',
      textAlign: 'center', 
  },
  paper: {    
    padding: '5px',
    marginBottom: '10px'
  },
  text:{
    color: '#231f20',
    marginBottom: '40px',
    marginTop: '40px',
    fontFamily:'Lato',
    fontSize: '42px'
  },
  link:{
      textDecoration: 'none',
      color: 'black'
  },

  mpagecontent: {
    fontFamily: 'Lato',
    fontSize: '20px'
  },
  pagecontent: {
    fontFamily: 'Lato',
    fontSize: '25px'
  }
});

function  Contact() {
  const classes = useStyles();
    return (      
      <div id="contact" style={{paddingTop: '50px', paddingBottom: '100px'}}>
   <Typography  align="center" className={classes.text} gutterBottom>
          Contact Us
        </Typography>       
        <div className={classes.content}>         
        <Container maxWidth="lg">       
          <Grid container spacing={4} alignItems="center" justify="center">
          <Grid item md={6}>
             {/* mobile */}
             <Box display={{ xs: 'block', sm: 'none' }}> 
                  <Typography style={{fontSize: '23px'}} align="center" className={classes.pagecontent}> Pro-Strand, a product of <br/>Sea-Land Distributors, LLC</Typography>
                </Box>
                {/* except mobile */}
                <Box display={{ xs: 'none', sm: 'block' }}>
                <Typography style={{fontSize: '28px'}} align="center" className={classes.pagecontent}>Pro-Strand, a product of <br/>Sea-Land Distributors, LLC </Typography>
                </Box>
              {/* mobile */}
           <Box display={{ xs: 'block', sm: 'none' }}> 
           <Typography   align="center"  className={classes.mpagecontent}>1000 Edwards Avenue, Suite C  <br/>
                </Typography>
                <Typography  className={classes.mpagecontent} align="center">New Orleans, LA 70123 USA
                </Typography>
                <Typography align="center" className={classes.mpagecontent}><strong>Hours: </strong>  Monday-Friday </Typography>   
                <Typography align="center" className={classes.mpagecontent}>
                  8 a.m. - 5 p.m.   CT
                </Typography> 
                </Box>
                {/* except mobile */}
                <Box display={{ xs: 'none', sm: 'block' }}>
                <Typography   align="center"  className={classes.pagecontent}>1000 Edwards Avenue, Suite C  <br/>
                </Typography>
                <Typography  className={classes.pagecontent} align="center">New Orleans, Louisiana 70123 USA
                </Typography>
                <Typography align="center"   className={classes.pagecontent}><strong>Hours:</strong>  Monday-Friday   8 a.m. - 5 p.m.   CT
                </Typography> 
                </Box>                 
              <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Sea-Land+Distributors,+LLC./@29.955757,-90.193528,15z/data=!4m5!3m4!1s0x0:0xacc2bccf2da70d2d!8m2!3d29.955757!4d-90.193528">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Google_Maps_icon.svg/1200px-Google_Maps_icon.svg.png" alt="" className={classes.map}/></a>            
            </Grid>

            <Grid item md={6} >
            <Grid container spacing={2} alignItems="center" justify="center"  className={classes.pagecontent} style={{padding: '10px'}}>
            <Grid item xs={12} sm={6} md={5}  >
               <a href='tel:504-736-9477' className={classes.link}>
              <Paper className={classes.paper}>
              <CallIcon  style={{verticalAlign: 'middle'}}/>
              <br/><span style={{fontSize: '18px'}}>504-736-9477</span>
               </Paper></a>  
           </Grid>
          <Grid item xs={12} sm={6} md={5}  >
          <a href='mailto:info@pro-strand.com' className={classes.link}>
              <Paper className={classes.paper}>
              <MailOutlineIcon  style={{verticalAlign: 'middle'}}/><br/><span style={{fontSize: '18px'}}>info@pro-strand.com</span>      
              </Paper>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={10} >         
          <Link to='/contact-form' style={{textDecoration: 'none'}}>
              <Paper className={classes.paper}>
              <MessageIcon  style={{verticalAlign: 'middle'}}/><br/><span style={{fontSize: '18px'}}>Send us a Message</span>      
              </Paper></Link>             
            </Grid>
          </Grid> 
          </Grid>  
          </Grid>  
          </Container>   
        </div>  
      </div>
    );
  }

 
export default Contact;